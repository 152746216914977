<template>
  <div>
    <home-list-item :homelist="homelist"></home-list-item>
  </div>
</template>

<script>
import HomeListItem from '../../../home/homechild/HomeListItem'
// import {getCollections} from "network/user";
import { queryMyfocus } from 'network/follow'
export default {
  name: 'UserCollection',
  props: {
    user_id: {
      type: [Number, String]
    }
  },
  components: {
    HomeListItem
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  data() {
    return {
      homelist: [],
      pageIndex: 1,
      totalPages: 1
    }
  },
  created() {
    this.queryTradeList()
  },
  methods: {
    queryTradeList() {
      var that = this
      var pageIndex = that.pageIndex
      if (pageIndex <= that.totalPages) {
        queryMyfocus(0, that.pageIndex).then((res) => {
          that.homelist = that.homelist.concat(res.data.result.tradeList)
          that.totalPages = res.data.result.page.totalPages
          that.pageIndex = res.data.result.page.nowPage + 1
        })
      }
    },

    handleScroll() {
      //这里使用个延时加载，不然滑动屏幕的时候会不断触发方法，去计算高度，浪费性能
      let that = this
      let scrollTop
      clearTimeout(this.timer)
      that.timer = setTimeout(function() {
        scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        //获取到关键属性的值后，判断即可
        if (scrollTop + windowHeight >= scrollHeight) {
          var pageIndex = that.pageIndex
          if (pageIndex <= that.totalPages) {
            queryMyfocus(0, that.pageIndex).then((res) => {
              that.homelist = that.homelist.concat(res.data.result.tradeList)
              that.totalPages = res.data.result.page.totalPages
              that.pageIndex = res.data.result.page.nowPage + 1
            })
          }
        }
      }, 500)
    }
  }
}
</script>

<style scoped></style>
